import React, { Component }  from 'react'


class Book extends Component {

    createBook() {
        var book;
        book += "<div class='showreel'>"
        for (let i = 0; i < 4; i+=1) {           
            book += "<video autoplay muted loop><source src='./book/ar"+(i+1)+".mp4' type='video/mp4'/></video>"
        }
        book += '</div>'
        return book;
    }

    createInstallations() {
        var book;
        for (let i = 0; i < 10; i+=2) {           
            book += "<div class='double-page'><img src='./book/"+i+".jpg' />"
            book += "<img src='./book/"+(i+1)+".jpg' /></div>"
        }
        return book;
    }
    
    render() {
        return (
            <div id="projects">
                <div class="project-category">
                    <div class="project-category-title">
                        AUGMENTED REALITY
                    </div>
                    <div dangerouslySetInnerHTML={{__html: this.createBook() }}></div>
                </div>
                <div class="project-category">
                    <div class="project-category-title">
                        BOOK
                    </div>
                    <div dangerouslySetInnerHTML={{__html: this.createInstallations() }}></div>
                </div>
            </div>
        )
    }
}

export default Book;
